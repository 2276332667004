<template>
    <div class="consumible-container">
        <div class="overlay" v-if="!allowed_to_buy"><span class="material-icons-outlined block-icon">block</span></div>
        <div class="patata">
            <div class="image-container">
                <img src="@/views/assets/mentoria.jpg" width="300px;" height="150">
            </div>
            <div class="text-container">
                <span class="title-text"><strong>{{ title }}</strong></span>
                <span class="price-text">{{ price + _getCurrencySimbol(currency)}}</span>
            </div>
            <div class="quantity-container">
                <form>
                    <div>
                        <label for="cantidad">Cantidad:</label>
                    </div>
                    <button type="button" :id="'decrementar'+id">-</button>
                    <input type="number" :id="'cantidad' + id" name="cantidad" value="1" min="1">
                    <button type="button" :id="'incrementar'+id">+</button>
                </form>
            </div>
            <div class="buy-container">
                <Button id="add-to-cart" 
                @_emit_method_button="_addToCart"  
                :button_icon="'add-to-cart-icon' + id"
                button_name="Añadir a la cesta" 
                :button_key="'add-to-cart-key' + id" 
                :button_icon_id="'add-to-cart-icon-id' + id" />
                <Button id="buy-now" 
                @_emit_method_button="_buyNow"  
                :button_icon="'buy-now-icon' + id" 
                button_name="Comprar ya" 
                :button_key="'buy-now-key' + id" 
                :button_icon_id="'buy-now-icon-key' + id" />
            </div>
        </div>
    </div>

</template>

<script>
import Button from "@/components/helpers/Button.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
  mixins: [APICaller],
  name: 'Consumibles',
  components: {
    Button,
  },
  props:{
    img_url: {type: String},
    title: {type: String},
    price: {type: Number},
    product_sku: {type: String},
    id: {type: String},
    currency: {type: String},
    allowed_to_buy: {type: Boolean},
  },
  data: () => ({
  }),
  mounted() {
    const decrementButton = document.getElementById("decrementar" + this.id);
    const incrementButton = document.getElementById("incrementar" + this.id);
    const quantityInput = document.getElementById("cantidad" + this.id);

    decrementButton.addEventListener("click", () => {
        const currentValue = parseInt(quantityInput.value);
        const minValue = parseInt(quantityInput.min);
        const newValue = currentValue > minValue ? currentValue - 1 : minValue;
        quantityInput.value = newValue;
    });

    incrementButton.addEventListener("click", () => {
        const currentValue = parseInt(quantityInput.value);
        const newValue =currentValue + 1;
        quantityInput.value = newValue;
    });
  },
  created: function() {
  },
  computed: {
  },
  methods: {
    _addToCart: function(){
      const quantityInput = document.getElementById("cantidad" + this.id);
      let params = new URLSearchParams();
      params.append('product_name', this.title);
      params.append('product_sku', this.product_sku);
      params.append('price', this.price);
      params.append('currency', this.currency);
      params.append('consumible_type', "unico");
      params.append('quantity', quantityInput.value);

      let success = ()=>{
        this.$vueOnToast.pop('success', "Producto añadido al carrito con éxito.");
        this.$emit("add_product");
      }


      let url = "/api/v1/eurekers_marketplace/edit-cart";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('537', 'Error editando el carrito.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },
    _buyNow: function(){
      let success = ()=>{
        const quantityInput = document.getElementById("cantidad" + this.id);
        let params = new URLSearchParams();
        params.append('product_name', this.title);
        params.append('product_sku', this.product_sku);
        params.append('price', this.price);
        params.append('currency', this.currency);
        params.append('consumible_type', "unico");
        params.append('quantity', quantityInput.value);

        let success = ()=>{
          window.location.href="/stripe/pay-shoping-cart";
        }


        let url = "/api/v1/eurekers_marketplace/edit-cart";

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('537', 'Error editando el carrito.');

        this._postAPICall(url, params, successHandler, failureHandler);
      }
      let url = "/api/v1/eurekers_marketplace/delete-cart";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('538', 'Error borrando el carrito.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _getCurrencySimbol: function(value){
        const dictCurrency = {
            'EUR': "€",
            'USD': "$",
        }

        return dictCurrency[value];
    },
  },
}

</script>

<style lang="scss" scoped>
.consumible-container{
    border: 1px solid black;
    background-color: white;
    width: 300px;
    height: 450px;
    transition: transform 0.2s ease-out;
}
.consumible-container:hover{
    transform: scale(1.05);
}
.text-container{
    text-align: center;
    margin-bottom: 20px;
    display: grid;
}
.quantity-container{
    text-align: center;
    margin-bottom: 20px;
}
.buy-container{
    text-align: center;
}
label {
  font-weight: bold;
}

input[type="number"] {
  width: 30px;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}
#add-to-cart{
    background-color: #FCD200;
    margin-bottom: 20px;
    color: black;
}
#buy-now{
    background-color: orange;
    color: black;
}
.price-text{
    font-weight: bold;
    font-size: 24px;
    margin: 5px 0 5px 0;
}
.title-text{
    font-size: 24px;
}
.consumible-container {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .patata {
    position: relative;
  }
  .block-icon{
    color: white;
    font-size: 345px;
    opacity: 0.6;
  }
  
</style>