<template>
    <div class="pop-up-container">
        <div class="confirm-redirect">
            <div class="pop-up-text">
                <p>Usted va a ser redirigido a la página del curso online asegurese de iniciar sesión en la web para tener todos sus datos disponibles.</p>
                <p>Puede iniciar sesión con el mismo usuario y contraseña que utiliza en la web de Eurekers.</p>
            </div>
            <div class="pop-up-buttons">
                <span></span>
                <Button id="cancel-button" 
                @_emit_method_button="_cancelRedirect"  
                button_icon="button_icon_cancel_redirect" 
                button_name="Cancelar" 
                button_key="button_key_cancelar_redirect" 
                button_icon_id="button_icon_id_cancelar_redirect" />
                <Button id="accept-button" 
                @_emit_method_button="_acceptRedirect"  
                button_icon="button_icon_aceptar_redirect" 
                button_name="Aceptar" 
                button_key="button_key_aceptar_redirect" 
                button_icon_id="button_icon_id_aceptar_redirect" />
                <span></span>
            </div>

        </div>
    </div>
</template>

<script>
import Button from "@/components/helpers/Button.vue";
export default {
  name: 'PopUpRedirectOnline',
  components: {
    Button,
  },
  data: () => ({
  }),
  mounted() {
  },
  created: function() {
  },
  computed: {
  },
  methods: {
    _acceptRedirect: function(){
        this.$emit("accept_pop_up_redirect");
    },
    _cancelRedirect: function(){
        this.$emit("show_pop_up_redirect", false);
        this.$emit("refresh_tab_index");
    }
  },
}

</script>

<style lang="scss" scoped>
.pop-up-container{
    position:absolute;
    width: 96.5vw;
    height: 99.99vh;
    background-color: rgb(95, 95, 95, 0.75);}
.confirm-redirect{
    position:absolute;
    width: 45vw;
    height: 35vh;
    background-color: white;
    top: 30%;
    left: 26%;
    opacity: 1;
    border: 8px solid #00add8;
    border-radius: 50px;
}

.pop-up-text{
    padding: 10px;
    text-align: center;
}
.pop-up-buttons{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10%;
}

#cancel-button{
    background-color: red;
}
</style>