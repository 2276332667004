<template>
    <div class="main-container">
        <div class="select-filters-container">
            <!-- <SelectOutline :ref="select_items.ref" :component_class="select_items.component_class"
              :label_name="select_items.label_name" :options="select_items.options"
              :default_value="select_items.default_value"
              @_emit_method_order_select="_getValueFromSelect" class="select-consumibles"/> -->
        </div>
        <div class="items-container">
            <div v-for="(item, index) in consumibles_to_show" :key="index" class="consumible-item-container">
                <div class="overlay"></div>
                <Consumible :title="item.product_name" :price="item.price" :currency="item.currency" :product_sku="item.product_sku" :allowed_to_buy="item.allowed_to_buy" @add_product="_addProduct()" :id="''+index"/>
            </div>
        </div>
    </div>

</template>

<script>
import Consumible from '@/views/Consumible.vue';
// import SelectOutline from '@/components/helpers/SelectOutline.vue';
// import selectorConsumibles from '@/views/configs/consumible-selector.json';
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";

export default {
  mixins: [APICaller],
  name: 'Consumibles',
  components: {
    Consumible,
    // SelectOutline,
  },
  data: () => ({
    // select_items: selectorConsumibles,
    consumibles_to_show: [],
    consumibles_data:[],
    user_roles: [],
  }),
  mounted() {
    this._getConsumiblesFromOdoo();
  },
  created: function() {
  },
  computed: {
    ...mapGetters(["_g_User"]),
  },
  methods: {
    _getValueFromSelect: function(value){
        var selected_category = value[0];
        const dictCategory = {
            'Todos':()=> {this.consumibles_to_show = this.consumibles_data},
            'Informe':()=>{this.consumibles_to_show = this.consumibles_data.filter(item => item.product_name.includes("Informe"))},
            'Platino':()=>{this.consumibles_to_show = this.consumibles_data.filter(item => item.product_name.includes("Platino"))},
            'Video tutorias':()=>{this.consumibles_to_show = this.consumibles_data.filter(item => item.product_name.includes("Video"))},
        }
        dictCategory[selected_category]();
    },
    _addProduct: function(){
        this.$emit("increment_cart_number");
    },
    _getConsumiblesFromOdoo: function(){
        let roles = ['2','20','21','27','13','14','15','16'];
    
        let rol = null;

        for (const key in this._g_User.roles) {
            if (roles.includes(key)) {
                rol = key;
            }
        }
        let success = response => {
            this.consumibles_data = response.data;
            this.consumibles_to_show = this.consumibles_data;
        }

        let url = "/api/v1/eurekers_marketplace/get-products/" + rol;

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('314', 'Error al recuperar los atributos de usuario.');

        this._getAPICall(url, successHandler, failureHandler);
    },
  },
}

</script>

<style lang="scss" scoped>
.main-container{
    height: 100%;
    width: 100%;
}
.consumible-item-container{
    display: inline-block;
}
.items-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.select-filters-container{
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}
.select-consumibles{
    width: 300px;
}
.overlay{
    background-color: red;
    height: 100%;
}
  
</style>