<template>
    <div class="primary-container" v-if="!(this._g_User.roles[21] || this._g_User.roles[20] || _g_User.is_only_authenticated_user)">
        <div class="header-store">
          <Tab class="tab-class" :tab_options="tab_obj" @_toggle_tab="_getTabIndex" />
          <div class="cart-container" @click="_goToCart"><span class="material-icons-outlined icon-cart">shopping_cart</span><span class="number-cart-products">{{ cart_number }}</span><span class="cart-text">Cesta</span></div>
          <PopUpRedirectOnline v-if="show_pop_up_redirect" @show_pop_up_redirect="show_pop_up_redirect=$event" @accept_pop_up_redirect="_acceptPopUpRedirect()" @refresh_tab_index="_refreshTabIndex()"/>
        </div>
        <div id="secondary-container" class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-12">
                <div v-if="active_index=='cursos'" class="iframe-container">
                    <Courses />
                </div>
                <div v-if="active_index=='suscripciones'" class="iframe-container">
                    <iframe name="pricing" id="pricing-id" src="/pricing/listar" style="overflow:hidden" width="100%" height="98%" frameBorder="0"></iframe>
                </div>
                <div v-if="active_index == 'consumibles'" class="iframe-container">
                  <Consumibles @increment_cart_number="_incrementCartNumber()" />
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="_g_User.is_only_authenticated_user" class="primary-container">
        <div id="secondary-container" class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-12">
                <div class="next-courses-container">
                    <div class="row">
                        <div class="back-to-tools">
                            <a href="/curso-de-bolsa"><div class="back-tool-btn">VER PRÓXIMOS CURSOS</div></a>
                        </div>
                    </div>
                </div>
                <div class="online-courses-container">
                    <div class="main-title-container">
                        <h3>Acceso a nuestra formación a distancia</h3>
                        <p class="subtitle">¡Descubre nuestros cursos a distancia de inversión!</p><br>
                    </div>
                    <div class="courses-wrapper">
                        <div class="basic-course-container">
                            <div class="title-container">
                                <p class="title"><strong>Acceso al curso básico</strong></p>
                            </div>
                            <div class="description-container">
                                <p>Curso de bolsa centrado en la psicología del mercado, la gestión de riesgos y la metodología de Eurekers. Con este curso adquirirás técnicas, análisis y herramientas para tomar decisiones de inversión sólidas.</p>
                            </div>
                            <div class="btn-to-course-container">
                                <a class="link-to-eurekers-courses" target="_blank" href="https://online.eurekers.com/curso-inicial-eurekers/">Ir al curso básico</a>
                            </div>
                        </div>
                        <div class="advanced-course-container">
                            <div class="title-container">
                                <p class="title"><strong>Acceso al curso avanzado</strong></p>
                            </div>
                            <div class="description-container">
                                <p>Curso de bolsa que proporciona a los inversores una visión más profunda y avanzada del mercado. Profundiza en una gestión global de la cartera y te da acceso a herramientas profesionales de inversión de uso exclusivo para Eurekers.</p>
                            </div>
                            <div class="btn-to-course-container">
                                <a class="link-to-eurekers-courses" target="_blank" href="https://online.eurekers.com/curso-avanzado-eurekers/">Ir al curso avanzado</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div id="secondary-container" class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-12">
                <div class="next-courses-container">
                    <div class="row">
                        <div class="back-to-tools">
                            <a href="/curso-de-bolsa"><div class="back-tool-btn">VER PRÓXIMOS CURSOS</div></a>
                        </div>
                    </div>
                </div>
                <div class="content-wrapper">
                    <div class="title-memberships-wrapper">
                        <div class="title-membership-content">
                            <p class="title-membership"> Planes de acceso a la web </p>
                        </div>
                    </div>
                    <div class="advanced-course-wrapper">
                        <div :class="is_mobile_device ? 'advanced-course-content-mobile' : 'advanced-course-content'">
                            <p class="advanced-course-text"> Usted no tiene acceso a los planes de suscripción. Debe completar la formación con el <a title="Curso Avanzado" class="link-to-online-advanced-course" href="https://online.eurekers.com/curso-avanzado-eurekers/" target="_blank"> Curso Avanzado </a> </p>
                        </div>
                    </div>
                    <div v-if="is_mobile_device" class="pricing-wrapper-mobile">
                        <img src="@/views/assets/pricing-club.png" alt="Pricing Club">
                        <img src="@/views/assets/pricing-silver.png" alt="Pricing Silver">
                        <img src="@/views/assets/pricing-gold.png" alt="Pricing Gold">
                        <img src="@/views/assets/pricing-platinum.png" alt="Pricing Platinum">
                    </div>
                    <div v-else class="pricing-wrapper">
                        <div class="pricing-container">
                            <img src="@/views/assets/pricing.png" class="pricing-image" alt="Pricing Eurekers">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tab from '@/components/helpers/Tab.vue';
import Consumibles from '@/views/Consumibles.vue';
import Courses from '@/views/Courses.vue';
import PopUpRedirectOnline from '@/views/Pop_up_redirect_online.vue';
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
  mixins: [APICaller],
  name: 'Store',
  components: {
    Tab,
    Consumibles,
    PopUpRedirectOnline,
    Courses,
  },
  data: () => ({
    show_pop_up_redirect: false,
    cart_number: 0,
    isMobile: window.innerWidth < 901,
    tab_obj: [
        {
          tab_name: 'Suscripciones',
          tab_id: 'suscripciones'
        },
        {
          tab_name: 'Consumibles',
          tab_id: 'consumibles'
        },{
          tab_name: "Cursos",
          tab_id: "cursos"
        }
      ],
      active_index: "suscripciones",
      show_cart: false,
  }),
  mounted() {
    this._setIframe();
    this._getProducts();
  },
  created: function() {
  },
  computed: {
    ...mapGetters(["_g_IsUserLogged"]),
    ...mapGetters(["_g_User"]),
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _refreshTabIndex: function(){
      const button = document.getElementById('suscripciones');
      if (button) {button.click();}
    },
    _getTabIndex(value) {
      this.active_index = value;
    },
    _setIframe(){
        if(this._g_IsUserLogged){
            var pricingFrame = document.getElementById("pricing-id");
            pricingFrame.src = process.env.VUE_APP_API + '/pricing/listar'
        }else{
            this._goToCourses();
        }
      
    },
    _acceptPopUpRedirect: function(){
      this.show_pop_up_redirect = false;
      this._refreshTabIndex();
      this._goToCourses();
    },
    _goToCourses: function(){
      window.location.replace(process.env.VUE_APP_API + "/curso-de-bolsa?autoopen=nextcourses");
    },
    _goToCart: function(){
      window.location.href = "/app/cart"
    },
    _incrementCartNumber: function(){
      this._getProducts();
    },

    _getProducts: function(){
      let success = response => {
        this.cart_number = 0;
        let prod = response.data.value.products;
        for(let key in prod){
          this.cart_number = this.cart_number + parseInt(prod[key]["quantity"]);
        }
      }

      let url = "/api/v1/eurekers_marketplace/get-cart";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('510', 'Error al recuperar los productos.');

      this._getAPICall(url, successHandler, failureHandler);
    },

  },
}

</script>

<style lang="scss" scoped>

  #secondary-container{
    height:100%;
    width:100%;
  }

  .primary-container{
    height:93%;
    width:100%;
  }

  .iframe-container{
    height:100% !important;
  }
  
  header{
    display:none !important;
  }

  .next-courses-container {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #444242;
    border-bottom: 2px solid black;
  }
  
  .back-tool-btn {
    width: 100%;
    max-width: 300px;
    background: #3fadd8;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
    margin: auto;
    font-weight: bold;
  }

  a{
    text-decoration: none;
  }

  .content-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-membership{
    font-weight: bold;
    font-size: 25px;
  }

  .advanced-course-content{
    background-color: #FF9B00;
    padding: 2px 180px 2px 180px;
    border-radius: 15px;
    margin-bottom: 10px;
  }

  .advanced-course-content-mobile{
    text-align: center;
    background-color: #FF9B00;
  }

  .advanced-course-text{
    font-size: 19px;
  }

  .link-to-online-advanced-course{
    text-decoration: underline;
  }

  .pricing-wrapper-mobile{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .tab-class{
    width: 92%;
  }
  .cart-container{
    float: right;
    margin-right: 60px;
    margin-top: 10px;
  }
  .cart-container:hover{
    cursor: pointer;
  }
  .icon-cart{
    font-size: 35px;
    color: var(--color-text-primary);
  }
  .cart-text{
    font-size: 14px;
    font-weight: bold;
    color: var(--color-text-primary);
  }
  .header-store{
    display: flex;
  }
  .number-cart-products{
    color: red;
    position: absolute;
    top: 3px;
    font-weight: bold;
    font-size: 20px;
  }

  .main-title-container{
    text-align: center;
  }

  .courses-wrapper{
    margin-top: 20px !important;
    width: 50%;
    margin: auto;
    display: inline;
    text-align: -webkit-center;
    height: 100%;
  }

  .basic-course-container{
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    padding: 20px;
    width: 50%;
    height: 100%;
  }

  .advanced-course-container{
    margin-top: 50px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    padding: 20px;
    width: 50%;
    height: 100%;
  }

  .link-to-eurekers-courses{
    display: block;
    background-color: #00add9;
    color: white;
    text-align: center;
    border-radius: 20px;
    height: 25px;
    width: 200px;
    padding: 10px;
  }

  .link-to-eurekers-courses:active{
    transform: translateY(2px);
  }

  .title{
    color: #0F0F1A;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.15px;
    font-weight: 600;
    margin-bottom: 35px;
  }

  .subtitle{
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin-bottom: 35px;
  }
  
</style>